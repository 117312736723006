.patient-name {
    text-align: center;
}

.section {
    font-size: 110%;
    font-weight: 500;
}

.back-button {
    color: white;
    background-color: rgb(167, 130, 37);
    width: 3%;
}

.dropButton {
    max-height: 200px;
    overflow-y: scroll;
}