/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  position: absolute;
  top: 0;
}

.tableHeaders {
  font-weight: bold;
  font-size: 125%;
}


.header-center {
	text-align: center;
}

.carousel-caption h2 {
  color: #fff;
}

.carousel img {
  height: 300px;
  padding: 30px;
  margin: 40px;

  margin-left: auto;
  margin-right: auto;
}
.carousel a {
  margin: 0 auto;
}
@media (max-width: 900px) {
  .carousel-caption h2 {
    font-size: 2.5vw;
  }
}

/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  position: absolute;
  top: 0;
}

.carousel-caption h2 {
  color: #fff;
}

.carousel img {
  height: 300px;
  padding: 30px;
  margin: 40px;

  margin-left: auto;
  margin-right: auto;
}
.carousel a {
  margin: 0 auto;
}

.center {
  display: flex;
  justify-content: center;
}

.linksNav {
  font-size: 130%;
}

.flex1 {
  display: flex !important;
  justify-content: space-around;
}

.loginHeader {
  text-align: center;
}

.loginForm {
  border: 2px solid rgba(0, 0, 0, 0.189);
  border-radius: 5px;
  margin-top: 10%;
}

.registerForm {
  border: 2px solid rgba(0, 0, 0, 0.189);
  border-radius: 5px;
  margin-top: 3%;
}

.accordianHeader {
  font-size: 175%;
  background-color: white;
}

.accordianMain {
  text-align: center;
}

.changePassword {
  text-underline-offset: none;
}
.footer {
  background-color: #343a40;
  padding: 20px 0px;
  color: white;
  bottom: 0;
  margin-top: 5%;
}
.fab {
  font-size: 175%;
}
.fas {
  font-size: 175%;
}
.icon {
  color: white;
}
.footer-links {
  color: white;
}

/* Trick: */
.body1 {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}

.main1 {
  flex: 1;
}

.badgesAcc {
  color: white;
}

.slotButton-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.slotButton-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.slotButton-success:focus {
  color: #fff;
  background-color: #ddfe07;
  color: #000000;
  border-color: #bcd800;
  
}

.slotButton {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.heading1 {
  text-align: center;
}

.flex321 {
  display: flex;
  flex-direction: row;
}


.required::after{
	content:"*";
	color: red;
	font-size:20px;
}
.ppppp{
  margin-left: -30px; cursor: pointer;
                
}

.usersColor {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  background-color: slategrey;
  color: white;
}